import { graphql } from 'gatsby'
import React from 'react'

import animation from '../../assets/blog/9/1.gif'
import img1 from '../../assets/blog/9/2.jpg'
import img2 from '../../assets/blog/9/3.jpg'
import '../../styles/global.css'
import { ContentMain } from '../../components/common/content-main'
import { ContentSubPage } from '../../components/common/content-sub-page'
import { HeaderTitle } from '../../components/common/header-title'
import { CookiesBox } from '../../components/cookies-box'
import { Footer } from '../../components/footer'
import { Navigation } from '../../components/navigation'
import { SEO } from '../../components/seo'

const BlogPage = () => (
  <>
    <SEO title="Spasí nás kultura?" />
    <div className="flex flex-col h-screen justify-between">
      <Navigation />
      <ContentMain>
        <HeaderTitle title="Spasí nás kultura?" />
        <ContentSubPage>
          <p>
            Vytržení z každodenního shonu, chvilka k zastavení, odpočinku,
            načerpání inspirace nebo nových zážitků. To pro mnohé z nás
            představuje výlet za kulturou. Ať už jde o divadelní představení,
            koncert nebo výstavu, prohlídku města s průvodcem nebo třeba
            návštěvu kina. Dozvídáme se víc o světě kolem i o sobě.
          </p>
          <p>
            Nedávná pandemie našimi kulturními zvyky pořádně zacvičila. Přišli
            jsme na chuť podcastům, Netflixu, Spotify a dalším streamovacím
            platformám. A přestože nám sdílení kulturních zážitků chybělo, za
            kulturou vyrážíme i dnes pořád méně, než před pandemií. Vstupenky
            kupujeme na poslední chvíli, podle výzkumů vyhledáváme spíše
            oddechovou zábavu než náročnější zážitky. Důvodů je samozřejmě víc,
            ke změně chování způsobené lockdowny se připojuje strmá inflace nebo
            úzkosti a deprese z nedaleké války a ohrožení klimatu.
          </p>
          <p>
            Společný kulturní zážitek je ale pořád nenahraditelný. Fakt, že v
            kině prožijeme film mnohem intenzivněji, než při sledování na malé
            obrazovce doma, komentoval i Martin Pošta, ředitel Asociace
            provozovatelů kin. Za pravdu mu dávají i hollywoodská studia, kde
            mají dobře spočítáno, že je pořád mnohem výhodnější uvádět nové
            filmy poprvé v klasických kinech.
          </p>
          <p>
            Poslední dobou se ale do popředí dostává ještě jedna zásadní
            vlastnost kulturních událostí. Dnes už víme, že se společnost
            opravdu štěpí a jednotlivé skupiny se radikalizují. Jsme čím dál
            méně tolerantní k odlišným názorům, žijeme v sociálních bublinách,
            zejména v online prostoru. Výlet za kulturou nás naopak vystavuje
            jinému názoru, ať už ze strany umění nebo spolunávštěvníků. Ocitáme
            se tak trochu mimo naši komfortní zónu, jsme nuceni interagovat ve
            skutečném světě, otevíráme se a nasloucháme. A to je účinný protijed
            na neúprosné a přesně opačně nastavené algoritmy sociálních sítí.
            Jestli ovšem bude stačit, ukáže až budoucnost.
          </p>
          <p>
            Do té doby, hurá za kulturou. Do diáře totiž patří nejen povinnosti,
            ale i plánované radosti. U těch kulturních nás navíc může hřát, že
            nejsou prospěšné jen pro nás samotné.
          </p>

          <div className="my-5">
            <a href={animation} target="_blank" rel="noreferrer">
              <img
                src={animation}
                alt="Demo showcase"
                className="object-contain"
              />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img1} target="_blank" rel="noreferrer">
              <img src={img1} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
          <div className="my-5 flex justify-around">
            <a href={img2} target="_blank" rel="noreferrer">
              <img src={img2} alt="Demo showcase" className="object-contain" />
            </a>
          </div>
        </ContentSubPage>
      </ContentMain>
      <Footer />
    </div>
    <CookiesBox />
  </>
)

export default BlogPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
